
<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Leave Team
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <form
        v-if="canLeave"
        @submit.prevent="onSubmit"
      >
        <div class="mb-6">
          <p class="text-sm">
            Are you sure you want to leave the following team?
          </p>

          <div class="py-2 px-4 my-2 border border-gray-300 rounded flex justify-between">
            <div class="text-sm">
              {{ team.name }}
            </div>
            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {{ team.team_role }}
            </span>
          </div>

          <div class="text-sm text-red-500">
            You won't be able to undo this action yourself.
          </div>
        </div>

        <div class="mt-6 btn-group flex items-center justify-end">
          <button
            v-if="!form.busy"
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            v-if="form.busy"
            class="btn"
            type="button"
          >
            <fa-icon
              icon="spinner"
              spin
            />
          </button>
          <button
            v-else
            class="btn negative"
            type="button"
            @click.prevent="onSubmit"
          >
            Leave Team
          </button>
        </div>
      </form>
      <div v-else>
        <div class="py-2 px-4 my-2 border border-gray-300 rounded">
          <div>
            {{ team.name }}
            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {{ team.team_role }}
            </span>
          </div>
        </div>

        <div class="text-red-500 italic">
          <p><fa-icon icon="triangle-exclamation" /> Sorry, you can't leave a team you own.</p>
          <p class="text-sm">
            You can <span class="font-bold">close this team</span> from the team settings page
          </p>
        </div>

        <div class="mt-6 btn-group flex items-center justify-end">
          <a
            href="#"
            class="underline cursor-pointer"
          >Team Settings &rarr;</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeamLeave',

  props: {
    team: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: new SparkForm({})
    }
  },

  computed: {
    canLeave () {
      return this.team.team_role !== 'owner'
    }
  },

  methods: {
    onSubmit () {
      this.form.startProcessing()
      this.$store.dispatch('removeTeamMember', { team: this.team, member: this.user })
        .then(() => {
          this.$store.dispatch('teams/removeTeam', { id: this.team.id })

          // Force a full page refresh
          this.form.finishProcessing()
          alert.success('Left ' + this.team.name + ' team')

          this.$emit('close')
          // window.location.replace('/')
        })
        .catch(() => {
          alert.error('Error leaving team')
          // this.closeModal()
        })
    }
  }
}
</script>
